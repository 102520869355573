import './category.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Categorydata from '../../components/categorydata/Categorydata';

const Category = () => {
  return (
    <div>
      <div className="category">
        <Sidebar />
        <div className="categoryContainer">
          <Navbar />
          <Categorydata />
        </div>
      </div>
    </div>
  );
};

export default Category;
