import React, { useState, useEffect } from 'react';
import './queuedata.scss';
import '../topbar/topbar.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

const Opendata = () => {
  const [opencashs, setOpencashs] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOpenCashs = async () => {
      try {
        // const response = await fetch(`${apiUrl}/opencashs`);
        const response = await fetch(`${apiUrl}/finances`);
        if (!response.ok) {
          throw new Error('Failed to fetch open cash data');
        }
        const data = await response.json();
        console.log(data)
        setOpencashs(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOpenCashs();
  }, []);



  return (
    <div>
      <div className="navbar">
        <div className="wrapper">
          <div className="items">
            <div className="item">
              <Link to="/openbalances/addOpen" style={{ textDecoration: 'none' }}>
                <AddIcon className="icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper} className="reporttable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Merchant ID</TableCell>
              <TableCell className="tableCell">Opening Amount</TableCell>
              <TableCell className="tableCell">Total Calculated Value</TableCell>
              <TableCell className="tableCell">Notes</TableCell>
              <TableCell className="tableCell">Created At</TableCell>
              {/* Add more table headers if needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {opencashs.map((opencash) => (
              <TableRow key={opencash._id}>
                <TableCell>{opencash.merchantId}</TableCell>
                <TableCell className="tableCell">{opencash.openingAmount}</TableCell>
                <TableCell className="tableCell">{opencash.totalCalculatedValue}</TableCell>
                <TableCell className="tableCell">{opencash.notes}</TableCell>
                <TableCell className="tableCell">{opencash.createdAt}</TableCell>
                {/* Add more table cells if needed */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Opendata;
