import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import 'react-circular-progressbar/dist/styles.css';
import './cart.scss';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Cart = ({ tableId, orderId }) => {
  const componentRef = useRef();
  const [orderDetails, setOrderDetails] = useState(null);
  const [updateStatus, setUpdateStatus] = useState('');
  const [updatePendingOrderStatus, setUpdatePendingOrderStatus] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showSplitDialog, setSplitShowDialog] = useState(false);
  const [showCombineDialog, setCombineShowDialog] = useState(false);
  const [splitUpdateShowDialog, setSplitUpdateShowDialog] = useState(false);
  const [cashAmount, setCashAmount] = useState('');
  const [cashSingleOrderAmount, setCashSingleOrderAmount] = useState('');
  const [balance, setBalance] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pendingOrders, setPendingOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [combinedBill, setCombinedBill] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('');

  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  // Function to open the dialog for single payment details
  const openSplitUpdateDialog = (orderId) => {
    setSelectedOrderId(orderId); // Set the selected orderId state
    setSplitUpdateShowDialog(true); // Set the showSplitUpdateDialog state to true
  };

  // Function to close the dialog for single payment details
  const closeSplitUpdateDialog = () => {
    setSplitUpdateShowDialog(false); // Set the showSplitUpdateDialog state to false
    setSelectedOrderId(null); // Reset the selected orderId state
  };
  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
  };

  const openDialog = (combinedBill) => {
    setShowDialog(true);
    console.log(combinedBill)
    setOrderDetails(combinedBill); // Set the order details in the state
  };
  const closeDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        if (!tableId) {
          console.error('Table ID is not provided');
          return;
        }

        const storedToken = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/orders/tableId?tableId=${tableId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Fetched order details:', data);

        if (Array.isArray(data) && data.length > 0 && data[0].isTableAvailable) {
          setOrderDetails(data[0]);
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    if (tableId) {
      fetchOrderDetails();
    }
  }, [tableId]);


  const openCombineDialog = async () => {
    try {
      // Fetch the combined bill from the API
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/orders/combine/tableId?tableId=${tableId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched combined bill:', data);

      // Check if data is not empty and isTableAvailable is true
      if (data && data.isTableAvailable) {
        // Set the combined bill details
        setCombinedBill(data);
        setCombineShowDialog(true); // Open the combine dialog
      } else {
        // If no data or isTableAvailable is false, show a message
        console.error('Combined bill not found');
        //  can show a message to the user or handle the case accordingly
      }
    } catch (error) {
      console.error('Error fetching combined bill:', error);
      // Handle the error (e.g., show an error message)
    }
  };




  // const handleOrderPayment = async (order) => {
  //   try {
  //      // Update order status to 'paid'
  //     openDialog(); // Open the payment dialog
  //   } catch (error) {
  //     console.error('Error handling order payment:', error);
  //   }
  // };

  // Function to handle cancellation of an order
  const handleOrderCancellation = (order) => {
    // Implement logic for handling order cancellation
  };

  useEffect(() => {
    if (orderDetails && cashAmount !== '') {
      const total = orderDetails.grandTotal;
      setGrandTotal(total);
      const cash = parseFloat(cashAmount);
      setBalance(cash - total);
    }
  }, [cashAmount, orderDetails]);




  ;



  // const openSplitDialog = () => {
  //   setSplitShowDialog(true);
  // };
  const openSplitDialog = (order) => {
    setSelectedOrder(order);
    setSplitShowDialog(true);
  };


  const closeCombineDialog = () => {
    setCombineShowDialog(false);
  };
  const closeSplitDialog = () => {
    setSplitShowDialog(false);
  };


  const handleCashChange = (e) => {
    setCashAmount(e.target.value);
  };
  // const handleCashSingleOrderChange = (e) => {
  //   setCashSingleOrderAmount(e.target.value);
  // };



  const handleCombineBillPayment = async () => {
    console.log("Checking the tableID:", tableId);
    try {
      const storedToken = localStorage.getItem('accessToken');
      console.log("Retrieved access token:", storedToken);

      // Prepare the data for the request
      const data = {
        status: 'paid',
        isTableAvailable: false,
      };

      // Configure the request
      const config = {
        method: 'put',
        url: `${apiUrl}/orders/combine/${tableId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        data: data,
      };

      // Make the request
      const response = await axios.request(config);
      console.log('Response data:', response.data);

      // Update state on success
      setUpdateStatus('success');
    } catch (error) {
      console.error('Error updating order status:', error.response ? error.response.data : error.message);

      // Update state on error
      setUpdateStatus('error');
    }
  };


  const handleSplitBill = async () => {
    setLoading(true);

    try {
      if (!tableId) {
        console.error('Table ID is not provided');
        return;
      }

      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/orders/pending/tableId?tableId=${tableId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSplitShowDialog(true);
      console.log('Fetched pending new orders:', data);
      setPendingOrders(data);
    } catch (error) {
      console.error('Error fetching pending orders:', error);
    } finally {
      setLoading(false); // Set loading back to false after fetching orders
    }
  };


  const handlePendingBillPayment = async (orderId) => {
    console.log('Checking the tableID:', tableId);
    console.log('Checking the Order ID:', orderId);
    try {
      const storedToken = localStorage.getItem('accessToken');
      console.log('Retrieved access token:', storedToken);

      const data = {
        status: 'paid',
        isTableAvailable: false,
      };

      const config = {
        method: 'put',
        url: `${apiUrl}/orders/pending/${orderId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log('Response data:', response.data);

      setUpdatePendingOrderStatus('success');
    } catch (error) {
      console.error('Error updating order status:', error.response ? error.response.data : error.message);

      setUpdatePendingOrderStatus('error');
    }
  };


  const handleCashSingleOrderChange = (e) => {
    setCashSingleOrderAmount(e.target.value);
  };

  return (
    <div className="featured">
      <div>
        {/* <button onClick={openQrPopup}> QrCode</button> */}
      </div>
      {updateStatus === 'success' && <p>Payment successfully!</p>}
      {updateStatus === 'error' && <p>Error updating order. Please try again later.</p>}
      {orderDetails ? (
        <div className="orderSummary" ref={componentRef}>
          <div className="orderDetails">
            <h2>{orderDetails.merchantId.username}</h2>
            <p>{orderDetails.merchantId.address}</p>
            <p>{orderDetails.merchantId.phone}</p>

          </div>
          <div className="orderDetails">
            <h3>{`Order ID: ${orderDetails._id}`}</h3>
            <p>{`Table No: ${orderDetails.tableId.tableno}`}</p>
            <p>{`Created At: ${new Date(orderDetails.createdAt).toLocaleString()}`}</p>
          </div>
          {orderDetails.foods && Array.isArray(orderDetails.foods) ? (
            <div className="orderItems">
              <div className="itemContent">
                {orderDetails.foods.map((food, index) => (
                  <div key={index}>
                    <p>{`${food.quantity} - ${food.foodId.title} (RM ${food.foodId.price.toFixed(2)})`}</p>
                    {food.requests && Array.isArray(food.requests) && (
                      <div>
                        {food.requests.map((request, reqIndex) => (
                          <p key={reqIndex}>{`-${request.name} (+RM ${request.addOnPrice.toFixed(2)})`}</p>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                <div>
                  <p>Sub Total: {`RM ${orderDetails.subtotal.toFixed(2)}`}</p>
                  <p>SST (8%): {`RM ${(orderDetails.amount * 0.08000).toFixed(2)}`}</p>
                  <p>Service Charge (10%): {`RM ${(orderDetails.amount * 0.10000).toFixed(2)}`}</p>
                  <h4>Grand Total: {`RM ${(orderDetails.grandTotal.toFixed(2))}`}</h4>
                </div>
              </div>
              <br />
            </div>
          ) : (
            <p>No food items found</p>
          )}
        </div>
      ) : (
        <p>{`${tableId
          ? 'No order found for the specified tableId'
          : 'Table ID is not provided'
          }`}</p>
      )}
      {orderDetails && orderDetails.isTableAvailable && (
        <button className="update" onClick={openDialog}>
          Payment
        </button>
      )}
      {orderDetails && orderDetails.isTableAvailable && (
        <button className="update" onClick={openCombineDialog}>
          Combine Bill
        </button>
      )}

      {orderDetails && orderDetails.isTableAvailable && (
        <button className="update" onClick={handleSplitBill}>Split Bill</button>
      )}
      {orderDetails && !orderDetails.isTableAvailable && (
        <p>No order available for this table</p>
      )}



      {/* {showDialog && (
        <div className="dialog-overlay" style={{ zIndex: 2 }}>
          <div className="dialog">
            <button className="close-btn" onClick={closeDialog}>Close</button>
            <h2>Payment Details</h2>
            {updateStatus === 'success' && (
              <p className="success-message" style={{ color: 'green' }}>Payment successfully!</p>
            )}
            {updateStatus === 'error' && (
              <p className="error-message">Error updating order. Please try again later.</p>
            )}
            <p>Order Total: {`RM ${(orderDetails.grandTotal.toFixed(2))}`}</p>
            <p>Grand Total: {`RM ${(orderDetails.grandTotal.toFixed(2))}`}</p>
            <p>Balance: RM {balance.toFixed(2)}</p>
            <label htmlFor="cashAmount">Cash Amount:</label>
            <input type="number" id="cashAmount" value={cashAmount} onChange={handleCashChange} />
            {/* Pass the tableId to combineBillPayment */}
      {/* {grandTotal === parseFloat(cashAmount) && (
              <button onClick={() => {
                console.log("TableId:", tableId); // Log tableId to console
                handleCombineBillPayment();
              }}> Combine Pay</button>
            )}
            {/* Render a disabled button if grandTotal !== cashAmount */}
      {/* {grandTotal !== parseFloat(cashAmount) && (
              <button disabled> Combine Pay</button>
            )}
          </div>
        </div>
      )}  */}

      {showDialog && (
        <div className="dialog-overlay" style={{ zIndex: 2 }}>
          <div className="dialog">
            <button className="close-btn" onClick={closeDialog}>Close</button>
            <h2>Payment Details</h2>
            {updateStatus === 'success' && (
              <p className="success-message" style={{ color: 'green' }}>Payment successfully!</p>
            )}
            {updateStatus === 'error' && (
              <p className="error-message">Error updating order. Please try again later.</p>
            )}
            <p>Order Total: {`RM ${(orderDetails.grandTotal.toFixed(2))}`}</p>
            <p>Grand Total: {`RM ${(orderDetails.grandTotal.toFixed(2))}`}</p>
            <p>Balance: RM {balance.toFixed(2)}</p>
            <label htmlFor="cashAmount">Cash Amount:</label>
            <input type="number" id="cashAmount" value={cashAmount} onChange={handleCashChange} />
            {/* Render the button conditionally based on cash amount */}
            {parseFloat(cashAmount) >= grandTotal && (
              <button onClick={() => {
                console.log("TableId:", tableId); // Log tableId to console
                handleCombineBillPayment();
              }}> Combine Pay</button>
            )}
            {/* Render a disabled button if cash amount is less than grandTotal */}
            {parseFloat(cashAmount) < grandTotal && (
              <button disabled> Combine Pay</button>
            )}
          </div>
        </div>
      )}


      {showCombineDialog && (
        <div className="dialog-overlay" style={{ zIndex: 1 }}>
          <div className="dialog">
            <button className="close-btn" onClick={closeCombineDialog}>Close</button>
            <h2>Combined Orders</h2>
            {combinedBill ? (
              <div className="combined-orders">
                <h3>Combined Order Summary:</h3>
                <h4>{` ${combinedBill.merchantId.username}`}</h4>
                <p>{`Table No: ${combinedBill.tableId.tableno}`}</p>
                <p>{`Created At: ${new Date(combinedBill.updatedAt).toLocaleString()}`}</p>
                <div className='orderItems' style={{ maxHeight: '300px', overflowY: 'auto', fontSize: '15px' }}>
                  <ul>
                    {combinedBill.foods.map((food) => (
                      <li key={food._id}>
                        <p>{`${food.quantity} - ${food.title} (RM ${food.price.toFixed(2)})`}</p>
                        {food.requests && food.requests.map((request) => (
                          <p key={request._id}>{`-${request.name} (+RM ${request.addOnPrice.toFixed(2)})`}</p>
                        ))}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="totals">
                  <p>{`Total SST: RM ${combinedBill.sst.toFixed(2)}`}</p>
                  <p>{`Total Service Charge: RM ${combinedBill.serviceCharge.toFixed(2)}`}</p>
                  <p>{`Total Subtotal: RM ${combinedBill.subtotal.toFixed(2)}`}</p>
                  <p>{`Round Up: RM ${combinedBill.adjustment.toFixed(2)}`}</p>
                  <p>{`Total Grand Total: RM ${combinedBill.grandTotal.toFixed(2)}`}</p>
                  {/* <button onClick={() => {
              console.log("TableId check on click combine:", tableId); // Log tableId to console
              // combineBillPayment(tableId, combinedBill.orders);
              handleCombineBillPayment()
            }}>Print Bill</button> */}
                  {/* <button onClick={() => openDialog(combinedBill)} >Print Bill</button> */}
                  <button onClick={() => openDialog(combinedBill)} >Print Bill</button>
                </div>
              </div>
            ) : (
              <p>No combined orders available.</p>
            )}
          </div>
        </div>
      )}


{splitUpdateShowDialog && (
  <div className="dialog-overlay" style={{ zIndex: 2 }}>
    <div className="dialog">
      <button className="close-btn" onClick={closeSplitUpdateDialog}>Close</button>
      <h2>Single Payment Details</h2>
      {updatePendingOrderStatus === 'success' && (
        <p className="success-message" style={{ color: 'green' }}>Single Payment successfully!</p>
      )}
      {updatePendingOrderStatus === 'error' && (
        <p className="error-message">Error updating order. Please try again later.</p>
      )}
      {/* Use selectedOrderId instead of orderDetails.orderId */}
      <p>Order ID: {selectedOrderId}</p>
      <p>Grand Total: {`RM ${(orderDetails.grandTotal.toFixed(2))}`}</p>
      <p>Balance: RM {balance.toFixed(2)}</p>
      <label htmlFor="cashSingleOrderAmount">Cash Amount:</label>
      <input
        type="number"
        id="cashSingleOrderAmount"
        value={cashSingleOrderAmount}
        onChange={handleCashSingleOrderChange}
      />
      {/* Pass the selectedOrderId to handlePendingBillPayment */}
      {parseFloat(cashSingleOrderAmount || 0) >= parseFloat(orderDetails.grandTotal) ? (
        <button onClick={() => {
          console.log("TableId:", tableId); // Log tableId to console
          handlePendingBillPayment(selectedOrderId);
        }}> Single Pay</button>
      ) : (
        <button disabled> Single Pay</button>
      )}
    </div>
  </div>
)}



      {showSplitDialog && (
        <div className="dialog-overlay" style={{ zIndex: 1 }}>
          <div className="dialog">
            <button className="close-btn" onClick={closeSplitDialog}>Close</button>
            <h2>Pending Orders</h2>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="card-container">
                {/* Render list of pending orders */}
                {pendingOrders.map((order) => (
                  <div className="card" key={order.orderId} onClick={() => openDialog(order)}>
                    {/* Display order details */}
                    <div className="card-header">
                      <h3>{`Order ID: ${order.orderId}`}</h3>
                      <p>{`Table No: ${order.tableId.tableno}`}</p>
                      <p>{`Created At: ${new Date(order.updatedAt).toLocaleString()}`}</p>
                    </div>
                    <div className="card-body">
                      <h4>Items:</h4>
                      {/* Display food items */}
                      {order.foods && Array.isArray(order.foods) ? (
                        <ul>
                          {order.foods.map((food, index) => (
                            <li key={index}>
                              {`${food.quantity} - ${food.foodId.title} (RM ${food.foodId.price.toFixed(2)})`}
                              {/* Display requests */}
                              {food.requests && Array.isArray(food.requests) && (
                                <ul>
                                  {food.requests.map((request, reqIndex) => (
                                    <li key={reqIndex}>{`-${request.name} (+RM ${request.addOnPrice.toFixed(2)})`}</li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No food items found</p>
                      )}
                      {/* Display order summary */}
                      <p>SST (8%): {`RM ${(order.amount * 0.08000).toFixed(2)}`}</p>
                      <p>Service Charge (10%): {`RM ${(order.amount * 0.10000).toFixed(2)}`}</p>
                      <p>Sub Total: {`RM ${order.subtotal.toFixed(2)}`}</p>
                      <h4>Round up: {`RM ${order.adjustment.toFixed(2)}`}</h4>
                      <h4>Grand Total: {`RM ${order.grandTotal.toFixed(2)}`}</h4>
                      {/* Add the payment button */}
                      {/* <button onClick={() => SingleOrderDialog(order.orderId)} >Print Bill</button> */}
                      {/* <button onClick={() => openDialog(order.orderId)} >Print Bill</button> */}
                      <button onClick={() => openSplitUpdateDialog(order.orderId)}>
                        payment
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}



    </div>
  );
};

export default Cart;
