import React, { useState, useEffect } from 'react';
import './tablecard.scss';
import Dialog from '@mui/material/Dialog';
import QRCode from 'react-qr-code';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Print } from '@mui/icons-material';

const Tablecard = ({ onSelect }) => {
  const [tableData, setTableData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [qrPopupOpen, setQrPopupOpen] = useState(false);
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [tableNo, setTableNo] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedTableId, setSelectedTableId] = useState('');

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/tables`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        console.log('Fetched table data:', data);
        setTableData(data);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }
    };

    const fetchOrders = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/orders`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        let data = await response.json();
        console.log('Fetched order data:', data);
        setOrderData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };

    fetchTables();
    fetchOrders();
  }, []);

  const openQrPopup = (tableId) => {
    setSelectedTableId(tableId);
    setQrPopupOpen(true);

    const selectedTable = tableData.find((table) => table._id === tableId);
    const accessToken = selectedTable.accessToken;

    fetch(`${apiUrl}/tables/qrurl/${tableId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((qrCodeData) => {
        setQRCodeUrl(qrCodeData.qrCodeUrl);
        setTableNo(qrCodeData.tableNo);
      })
      .catch((error) => console.error('Error fetching QR code data:', error));
  };

  const closeQrPopup = () => {
    setQrPopupOpen(false);
    setQRCodeUrl('');
  };

  const handleTableClick = async (tableId) => {
    console.log('Selected Table ID:', tableId);
    onSelect(tableId);

    try {
      const accessToken = localStorage.getItem('accessToken');
      await fetch(`${apiUrl}/tables/${tableId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ status: 'Occupied' }),
      });

      setTableData(prevTableData => {
        return prevTableData.map(table => {
          if (table._id === tableId) {
            return { ...table, status: 'Occupied' };
          }
          return table;
        });
      });
    } catch (error) {
      console.error('Error updating table status:', error);
    }
  };

  const getBackgroundColor = (tableId) => {
    if (loading) {
      return 'gray';
    }

    const table = tableData.find((t) => t._id === tableId);
    const orders = orderData.filter((o) => o.tableId === tableId);

    if (table && orders.length > 0) {
      const lastOrder = orders[orders.length - 1];
      return lastOrder.isTableAvailable ? 'orange' : 'green';
    } else {
      return 'green';
    }
  };

  const getColorTitle = (backgroundColor) => {
    switch (backgroundColor) {
      case 'orange':
        return 'Occupied';
      case 'green':
        return 'Available';
      default:
        return 'Unknown';
    }
  };

  const handleWebsiteView = (tableId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      fetch(`${apiUrl}/tables/qrurl/${tableId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const newWindow = window.open(data.qrCodeUrl, '_blank');
          if (newWindow) {
            newWindow.focus();
          } else {
            console.error('Error: Unable to open new window.');
          }
        })
        .catch((error) => console.error('Error fetching website URL:', error));
    } catch (error) {
      console.error('Error handling website view:', error);
    }
  };

  const printQrCode = () => {
    // Logic to call the API to print the QR code
    fetch(`${apiUrl}/tables/qrurlscan/${selectedTableId}`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
      // Handle response data
      console.log(JSON.stringify(data));
    })
    .catch(error => {
      console.error('Error printing QR code:', error);
    });
  };
  

  return (
    <div className="card-container">
      {tableData.map((table) => (
        <div
          key={table._id}
          className="card"
          style={{ backgroundColor: getBackgroundColor(table._id) }}
          onClick={() => handleTableClick(table._id)}
        >
          <div className="card-title">{table.tableno}</div>
          {getBackgroundColor(table._id) !== 'orange' && (
            <div className="card-button" onClick={() => openQrPopup(table._id)}>QR Code</div>
          )}
          <div className="card-content">
            <p>{getColorTitle(getBackgroundColor(table._id))}</p>
          </div>
          <div className="popupActions">
            {/* <IconButton onClick={closeQrPopup} color="primary">
              <CloseIcon />
            </IconButton>
            <Print /> */}
            <button onClick={() => handleWebsiteView(table._id)}>Add Order</button>
          </div>
        </div>
      ))}
      <Dialog open={qrPopupOpen} onClose={closeQrPopup}>
        <div className="popupContent">
          <p className="tableInfo">Table No: {tableNo}</p>
          {qrCodeUrl && <QRCode value={qrCodeUrl} />}
          <div className="popupActions">
            <IconButton onClick={() => printQrCode()} color="primary">
              <Print /> {/* Print icon */}
            </IconButton>
            <IconButton onClick={closeQrPopup} color="primary">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Tablecard;
