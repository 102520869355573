import { CircularProgressbar } from 'react-circular-progressbar';
import './featured.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import 'react-circular-progressbar/dist/styles.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const Featured = () => {
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title"> Total Sales</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={70} text="70%" strokeWidth={10} />
        </div>
        <p className="title"> Total Sales Today</p>
        <p className="amount">$450.10</p>
        <p className="desc"> Previous transaction processing</p>

        <div className="summary">
          <div className="item">
            <div className="itemTitle"> Today</div>
            <div className="itemResult position">
              <KeyboardArrowUpIcon fontSize="small" />
              <div className="resultAmount">$125.04</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle"> Last week</div>
            <div className="itemResult position ">
              <KeyboardArrowUpIcon fontSize="small" />
              <div className="resultAmount">$125.04</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle"> Last Month</div>
            <div className="itemResult negetive">
              <KeyboardArrowUpIcon fontSize="small" />
              <div className="resultAmount">$125.04</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
