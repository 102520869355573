import './userdata.scss';
import { DataGrid } from '@mui/x-data-grid';

const usercolumns = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: 'user', headerName: 'User', width: 130 },
  { field: 'email', headerName: 'Email', width: 150 },
  { field: 'role', headerName: 'Role', width: 150 },

  {
    field: 'phone',
    headerName: 'Phone',

    width: 130,
  },

  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'createAt', headerName: 'Create At', width: 130 },
];

const rows = [
  {
    id: 'US1101',
    user: 'Jhone Drow',
    role: 'Support',
    email: 'drow@mail.com',
    phone: '+6012548736',
    status: 'Pending',
    createAt: '12-01-2023',
  },
  {
    id: 'US1102',
    user: 'Jhon Drow',
    role: 'Sales',
    email: 'Tan@mail.com',
    phone: '+6012548736',
    status: 'Pending',
    createAt: '10-01-2023',
  },
  {
    id: 'US1103',
    user: 'Ros Lee',
    role: 'Marketing',
    email: 'jhon@mail.com',
    phone: '+6012548736',
    status: 'Approved',
    createAt: '11-01-2023',
  },
  {
    id: 'US1104',
    user: 'Tee',
    role: 'Finance',
    email: 'tee@mail.com',
    phone: '+6012548736',
    status: 'Approved',
    createAt: '2-01-2023',
  },
  {
    id: 'US1105',
    user: 'Necole tan',
    role: 'Admin',
    email: 'tan@mail.com',
    phone: '+6012548736',
    status: 'Pending',
    createAt: '12-01-2023',
  },
  {
    id: 'US1106',
    user: 'Joshowa',
    role: 'Finance',
    email: 'jos@mail.com',
    phone: '+6012548736',
    status: 'Pending',
    createAt: '10-01-2023',
  },
  {
    id: 'US1106',
    user: 'Jim',
    role: 'Marketing',
    email: 'jim@mail.com',
    phone: '+6012548736',
    status: 'Pending',
    createAt: '1-01-2023',
  },
  {
    id: 'US1107',
    user: 'Yau Lee',
    role: 'Marketing',
    email: 'yau@mail.com',
    phone: '+6012548736',
    status: 'Pending',
    createAt: '12-01-2023',
  },
  {
    id: 'US1108',
    user: 'Jhone Sdn Bhd',
    role: 'Sales',
    email: 'yusuf@mail.com',
    phone: '+6012548736',
    status: 'Pending',
    createAt: '2-01-2023',
  },
];

const Userdata = () => {
  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      renderCell: () => {
        return (
          <div className="cellAction">
            <div className="viewButton">View</div>
            <div className="deleteButton"> Delete</div>
            <div className="editButton">Edit</div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="userdata">
      <DataGrid
        rows={rows}
        columns={usercolumns.concat(actionColumn)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
};
export default Userdata;
