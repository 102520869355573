import './printer.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Printerdata from '../../components/printerdata/Printerdata';
const Printer = () => {
  return (
    <div className="report">
      <Sidebar />
      <div className="reportContainer">
        <Navbar />
        {/* <Topbar /> */}
        <Printerdata />
      </div>
    </div>
  );
};

export default Printer;
