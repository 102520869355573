import React, { useState } from 'react';
import './addtable.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';

const Addtable = () => {
  const [formData, setFormData] = useState({
    tableno: '',
    status: 'Available',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiData = {
        tableno: formData.tableno,
        status: formData.status,
      };
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/tables`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage('Table created successfully!');
        setErrorMessage(''); // Clear any previous error message
        console.log('Table created successfully:', responseData);
      } else {
        const errorResponse = await response.json();
        setErrorMessage(`Error creating table: ${errorResponse.message}`);
        setSuccessMessage(''); // Clear any previous success message
        console.error('Error creating table:', errorResponse.message);
      }
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setSuccessMessage(''); // Clear any previous success message
      console.error('Error:', error);
    }
  };

  return (
    <div className="addtable">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Table</h1>
        </div>
        <div className="bottom">
          <div className="right">
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label>Table No</label>
                <input type="text" name="tableno" placeholder=" Table No" value={formData.tableno} onChange={handleChange} />
              </div>
              
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addtable;
