import React, { useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Cart from '../../components/cart/Cart.jsx';
import './table.scss';
import Tablecard from '../../components/tablecard/Tablecard';

const Table = () => {
  const [selectedTable, setSelectedTable] = useState(null);

  const handleTableSelect = (tableId) => {
    setSelectedTable(tableId);
  };

  return (
    <div className="table">
      <Sidebar />
      <div className="tableContainer">
        <Navbar />
        <div className="bills">
          <Tablecard onSelect={handleTableSelect} />
          {selectedTable && <Cart tableId={selectedTable} />}
        </div>
      </div>
    </div>
  );
};

export default Table;
