import React, { useState, useEffect } from 'react';
import './productedit.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

const ProductEdit = ({ productId }) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const categories = ['Main course', 'Side Dish', 'Snacks', 'Desserts', 'Appetizer', 'Alcohol', 'Drinks'];

  // State to manage form data
  const [formData, setFormData] = useState({
    title: '',
    desc: '',
    categories: '',
    price: '',
  });

  // State to manage food update status
  const [foodUpdateStatus, setFoodUpdateStatus] = useState(null);

  // useEffect to fetch food details when the component mounts or when productId changes
  useEffect(() => {
    const fetchFoodDetails = async () => {
      try {
        if (!productId) {
          console.error('Product ID is not provided');
          return;
        }

        const storedToken = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/foods/find/${productId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Fetched food details:', data);

        setFormData({
          title: data.title || '',
          desc: data.desc || '',
          category: data.categories && data.categories.length > 0 ? data.categories[0] : '',
          price: data.price || '',
        });

        // Set the preview URL if an image is present in data
        if (data.img) {
          setPreviewUrl(`https://ttt-media.s3.ap-southeast-1.amazonaws.com/food/${data.img}`);
        }
      } catch (error) {
        console.error('Error fetching food details:', error);
      }
    };

    if (productId) {
      fetchFoodDetails();
    }
  }, [productId]);

  const uploadToS3 = async (file) => {
    try {
      const target = { Bucket: "ttt-media", Key: `food/${file.name}`, Body: file };
      const creds = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      };

      const parallelUploadS3 = new Upload({
        client: new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds }),
        params: target,
      });

      parallelUploadS3.on("httpUploadProgress", (progress) => {
        console.log(progress);
      });

      await parallelUploadS3.done();

      setPreviewUrl(`https://ttt-media.s3.ap-southeast-1.amazonaws.com/food/${file.name}`);
      setUploadStatus('Upload successful');
    } catch (e) {
      setUploadStatus(`Upload failed: ${e.message}`);
      console.log(e);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (file) {
        await uploadToS3(file);
      }

      const apiData = {
        title: formData.title,
        desc: formData.desc,
        categories: [formData.category],
        price: formData.price,
        img: file ? file.name : '',
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/foods/find/${productId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        setFoodUpdateStatus('Food updated successfully');
        console.log('Food updated successfully:', await response.json());
      } else {
        setFoodUpdateStatus(`Error updating food: ${response.statusText}`);
        console.error('Error updating food:', response.statusText);
      }
    } catch (error) {
      setFoodUpdateStatus(`Error: ${error.message}`);
      console.error('Error:', error);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Edit Food</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <span className="status-message">{uploadStatus}</span>
            <span className="status-message">{foodUpdateStatus}</span>
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label>Title</label>
                <input type="text" name="title" placeholder="Food title" value={formData.title} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label>Description</label>
                <input type="text" name="desc" placeholder="Food description" value={formData.desc} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label>Category</label>
                <select name="category" value={formData.category} onChange={handleChange}>
                  <option value="" disabled>Select a category</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <label>Price</label>
                <input type="text" name="price" placeholder="Food price" value={formData.price} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label htmlFor="file">
                  {' '}
                  Food Image <DriveFolderUploadIcon className="icon" />{' '}
                </label>
                <input type="file" id="file" style={{ display: 'none' }} onChange={handleFileChange} />
                {file && <img src={previewUrl} alt="Preview" style={{ maxWidth: '15%', marginTop: '1px' }} />}
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEdit;
