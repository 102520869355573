import { useState, useEffect } from 'react';
import './widget.scss';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const Widget = ({ type }) => {
  const [data, setData] = useState({
    title: '',
    isMoney: false,
    link: '',
    icon: null,
    value: 0
  });
  
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        switch (type) {
          case 'merchant':
            apiUrl = `${process.env.REACT_APP_API_URL}/merchant/total`;
            break;
          case 'shop':
            apiUrl = `${process.env.REACT_APP_API_URL}/foods/total`;
            break;
          case 'order':
            apiUrl = `${process.env.REACT_APP_API_URL}/orders/total`;
            break;
          case 'revenue':
            apiUrl = `${process.env.REACT_APP_API_URL}/orders/income`;
            break;
          default:
            throw new Error('Invalid widget type');
        }
  
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (response.ok) {
          const responseData = await response.json();
          setData(prevData => ({ ...prevData, value: responseData.value }));
        } else {
          throw new Error(`Failed to fetch data for ${type}`);
        }
      } catch (error) {
        console.error(`Error fetching data for ${type}:`, error);
      }
    };
  
    fetchData();
  }, [type]);

  useEffect(() => {
    let widgetData;
    switch (type) {
      case 'merchant':
        widgetData = {
          title: 'TOTAL SHOPS',
          isMoney: false,
          link: 'See all shops',
          icon: <StorefrontIcon className="icon" style={{ color: 'crimson', backgroundColor: 'rgba(255, 0, 0, 0.2)' }} />
        };
        break;
      case 'shop':
        widgetData = {
          title: 'TOTAL FOODS',
          isMoney: false,
          link: 'View all foods',
          icon: <ProductionQuantityLimitsIcon className="icon" style={{ color: 'goldenrod', backgroundColor: 'rgba(218, 165, 32, 0.2)' }} />
        };
        break;
      case 'order':
        widgetData = {
          title: 'TOTAL ORDERS',
          isMoney: false,
          link: 'View all orders',
          icon: <MonetizationOnIcon className="icon" style={{ color: 'green', backgroundColor: 'rgba(0, 128, 0, 0.2)' }} />
        };
        break;
      case 'revenue':
        widgetData = {
          title: 'TOTAL SALES',
          isMoney: true,
          link: 'View all sales',
          icon: <AccountBalanceWalletIcon className="icon" style={{ color: 'purple', backgroundColor: 'rgba(0, 0, 128, 0.2)' }} />
        };
        break;
      default:
        widgetData = {};
    }
    setData(prevData => ({ ...prevData, ...widgetData }));
  }, [type]);

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{data.isMoney ? 'RM': ''} {data.value}</span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
