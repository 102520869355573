import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import './topbar.scss';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Link } from 'react-router-dom';
const Topbar = () => {
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon className="icon" />
        </div>
        <div className="items">
          <div className="item">
            <Link to="/products/new" style={{ textDecoration: 'none' }}>
              <AddIcon className="icon" />
            </Link>
          </div>
          <div className="item">
            <DownloadIcon className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
