import './login.scss';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../logo.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  console.log('apiUrl:', apiUrl);
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to your Node.js login endpoint
      const response = await axios.post(`${apiUrl}/merchants/login`, {
        email,
        password,
      });
      

      // Assuming your server sends back a token on successful login
      const token = response.data.accessToken;

      // Store the token securely ( might want to use a state management library like Redux)
      localStorage.setItem('accessToken', token);


      
      // Redirect to the desired page upon successful login
      navigate('/');
    } catch (error) {
      // Handle login error (e.g., display an error message)
      console.error('Login failed:', error.message);
      setError('Wrong credentials. Please try again.');
    }
  };

  return (
    <div className="login">
      <div className="left">
        <div className="wrapper">
          <form>
            <h1>Merchant Login</h1>
            <div className="formInput">
              <label>Email</label>
              <input type="text" placeholder="johndoe@mail.com" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="formInput">
              <label>Password</label>
              <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            {error && <div className="error">{error}</div>}
            <button type="submit" onClick={handleLogin}>
              Login
            </button>
            <Link to="/register" style={{ textDecoration: 'none' }}>
              Register
            </Link>
        
          </form>
        </div>
      </div>
      <div className="right">
        <div className="top">
          <img src={logo} className="App-logo" alt="logo" width="40%" />
          <h2>Merchant Portal</h2>
        </div>
        <div className="bottom">{/* Add any additional content here */}</div>
      </div>
    </div>
  );
};

export default Login;
