import './finance.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Financedata from '../../components/financedata/Financedata';
const Finance = () => {
  return (
    <div className="finance">
      <Sidebar />
      <div className="financeContainer">
        <Navbar />
        {/* <Topbar /> */}
        <Financedata />
      </div>
    </div>
  );
};

export default Finance;
