import './user.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Userdata from '../../components/userdata/Userdata';
import Topbar from '../../components/topbar/Topbar';
const User = () => {
  return (
    <div className="user">
      <Sidebar />
      <div className="userContainer">
        <Navbar />
        <Topbar />
        <Userdata />
      </div>
    </div>
  );
};

export default User;
