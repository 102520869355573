import './queue.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
// import Queuedata from '../../components/queuedata/Queuedata';
import Opendata from '../../components/opendata/Opendata';
const Open = () => {
  return (
    <div className="report">
      <Sidebar />
      <div className="reportContainer">
        <Navbar />
        {/* <Topbar /> */}
        <Opendata />
      </div>
    </div>
  );
};

export default Open;
