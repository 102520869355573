// import Navbar from '../../components/navbar/Navbar';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Widget from '../../components/widget/Widget';
import Featured from '../../components/featured/Featured';
import './home.scss';
import Chart from '../../components/chart/Chart';
import List from '../../components/table/Table';

const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="merchant" />
          <Widget type="shop" />
          <Widget type="order" />
          <Widget type="revenue" />
        </div>
        <div className="charts">
          <Featured />
          <Chart aspect={3 / 1} title=" Revenue (Last 12 Months)" />
        </div>
        <div className="listContainer">
          <div className="listTitle"> Latest Transaction </div>
          <List />
        </div>
      </div>
    </div>
  );
};

export default Home;
