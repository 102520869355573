import React, { useState, useEffect } from 'react';
import './edit.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const Edit = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const { foodId } = useParams();
  const [foodDetails, setFoodDetails] = useState(null);

  const [formData, setFormData] = useState({
    title: '',
    desc: '',
    category: '',
    price: '',
    requests: [{ id: 1, name: '', addOnPrice: '' }], // Initial request field
  });
  const categories = ['Main course', 'Side Dish', 'Snacks', 'Desserts', 'Appetizer', 'Alcohol', "Drinks"];
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const addRequest = () => {
    const newRequest = {
      id: formData.requests.length + 1,
      name: '',
      addOnPrice: ''

    };
    setFormData({
      ...formData,
      requests: [...formData.requests, newRequest]
    });
  };


  useEffect(() => {
    // Fetch food details based on foodId and populate foodDetails state
    const fetchFoodDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/foods/find/${foodId}`);
        const data = await response.json();
        setFoodDetails(data);
  
        // Set formData state with foodDetails values
        if (data) {
          setFormData({
            title: data.title,
            desc: data.desc,
            category: data.categories[0], // Update category with existing value
            price: data.price,
            requests: data.requests.map((request, index) => ({
              id: index + 1,
              name: request.name,
              addOnPrice: request.addOnPrice
            })),
          });
          // Set previewUrl for image
          setPreviewUrl(data.img);
        }
      } catch (error) {
        console.error('Error fetching food details:', error);
      }
    };
  
    fetchFoodDetails();
  }, [foodId]); 
  

  const deleteRequest = (id) => {
    const updatedRequests = formData.requests.filter(request => request.id !== id);
    setFormData({
      ...formData,
      requests: updatedRequests
    });
  };

  const handleInputChange = (id, fieldName, value) => {
    const updatedRequests = formData.requests.map(request =>
      request.id === id ? { ...request, [fieldName]: value } : request
    );
    setFormData({
      ...formData,
      requests: updatedRequests
    });
  };

  const uploadToS3 = async (file) => {
    try {
      const target = { Bucket: "ttt-media", Key: `food/${file.name}`, Body: file };
      const creds = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      };

      const parallelUploadS3 = new Upload({
        client: new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds }),
        params: target,
      });

      parallelUploadS3.on("httpUploadProgress", (progress) => {
        console.log(progress);
      });

      await parallelUploadS3.done();

      setPreviewUrl(`https://ttt-media.s3.ap-southeast-1.amazonaws.com/food/${file.name}`);
      setUploadStatus('Upload successful');
    } catch (e) {
      setUploadStatus(`Upload failed: ${e.message}`);
      console.error(e);
    }
  };

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   if (name === 'requests') {
  //     const updatedRequests = [...formData.requests];
  //     updatedRequests[index] = value;
  //     setFormData({
  //       ...formData,
  //       requests: updatedRequests,
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   }
  // };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'requests') {
      const updatedRequests = [...formData.requests];
      updatedRequests[index] = value;
      setFormData({
        ...formData,
        requests: updatedRequests,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };




  // const handleAddRequestField = (e) => {
  //   e.preventDefault(); // Prevent the default form submission behavior

  //   // Add a new empty string to the requests array in the form data state
  //   setFormData({
  //     ...formData,
  //     requests: [...formData.requests, ''],
  //   });
  // };


  // const handleRemoveRequestField = (index) => {
  //   const updatedRequests = [...formData.requests];
  //   updatedRequests.splice(index, 1);
  //   setFormData({
  //     ...formData,
  //     requests: updatedRequests,
  //   });
  // };

  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setFile(selectedFile);
  
  //   if (selectedFile) {
  //     const objectUrl = URL.createObjectURL(selectedFile);
  //     setPreviewUrl(objectUrl);
  //   } else {
  //     setPreviewUrl(''); // Reset previewUrl if no file is selected
  //   }
  // };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
      
      // Generate full image URL
      const fullImageUrl = `https://ttt-media.s3.ap-southeast-1.amazonaws.com/food/${selectedFile.name}`;
      // Save full image URL in state
      setFormData({
        ...formData,
        img: fullImageUrl // Save the full image URL in the img field of formData
      });
    } else {
      setPreviewUrl(''); // Reset previewUrl if no file is selected
    }
  };
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     if (file) {
  //       await uploadToS3(file);
  //     }

  //     const apiData = {
  //       title: formData.title,
  //       desc: formData.desc,
  //       categories: [formData.category],
  //       price: formData.price,
  //       requests: formData.requests.filter(request => request.trim() !== ''), // Remove empty requests
  //       img: file ? file.name : '',
  //     };

  //     const response = await fetch(`${apiUrl}/foods`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(apiData),
  //     });

  //     if (response.ok) {
  //       alert('Food created successfully');
  //       navigate('/foods');
  //     } else {
  //       console.error('Error creating food:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (file) {
        await uploadToS3(file);
      }

      const apiData = {
        title: formData.title,
        desc: formData.desc,
        categories: [formData.category],
        price: formData.price,
        requests: formData.requests, // Include requests data
        img: file ? file.name : '',
      };
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/foods/${foodId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          token: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        alert('Food updated successfully');
        navigate('/foods');
      } else {
        console.error('Error creating food:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // return (
  //   <div className="new">
  //     <Sidebar />
  //     <div className="newContainer">
  //       <Navbar />
  //       <div className="top">
  //         <h1> Update Food</h1>
  //       </div>
  //       <div className="bottom">
  //         <div className="right">
  //           <span className="status-message">{uploadStatus}</span>
  //           <form onSubmit={handleSubmit}>
  //             <div className="formInput">
  //               <label>Title</label>
  //               <input type="text" name="title" placeholder="Food title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
  //             </div>
  //             <div className="formInput">
  //               <label>Desc</label>
  //               <input type="text" name="desc" placeholder="Food Description" value={formData.desc} onChange={(e) => setFormData({ ...formData, desc: e.target.value })} />
  //             </div>
  //             <div className="formInput">
  //               <label>Category</label>
  //               <select name="category" value={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
  //                 <option value="" disabled>Select a category</option>
  //                 {categories.map((category, index) => (
  //                   <option key={index} value={category}>
  //                     {category}
  //                   </option>
  //                 ))}
  //               </select>
  //             </div>

  //             <div>
  //               {formData.requests.map((request, index) => (
  //                 <div key={index}>
  //                   <input
  //                     type="text"
  //                     value={request.name}
  //                     onChange={(e) => handleInputChange(request.id, 'name', e.target.value)}
  //                     placeholder={`Request item ${index + 1}`}
  //                   />
  //                   <input
  //                     type="text"
  //                     value={request.addOnPrice}
  //                     onChange={(e) => handleInputChange(request.id, 'addOnPrice', e.target.value)}
  //                     placeholder={`RM ${index + 1}`}
  //                   />

  //                   <button onClick={() => deleteRequest(request.id)}>Delete</button>
  //                 </div>
  //               ))}
  //             </div>
  //             <button type="button" onClick={addRequest}>Add Request</button>

  //             <div className="formInput">
  //               <label>Price</label>
  //               <input type="text" name="price" placeholder="Price" value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
  //             </div>
  //             <div className="formInput">
  //               <label htmlFor="file">
  //                 Food Image <DriveFolderUploadIcon className="icon" />
  //               </label>
  //               <input type="file" id="file" style={{ display: 'none' }} onChange={handleFileChange} />
  //               {file && <img src={previewUrl} alt="Preview" style={{ maxWidth: '15%', marginTop: '1px' }} />}
  //             </div>
  //             <button type="submit">Submit</button>
  //           </form>
  //         </div>
  //       </div>
  //       </div>
  //       </div>
  //       )
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1> Update Food</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <span className="status-message">{uploadStatus}</span>
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label>Title</label>
                <input type="text" name="title" placeholder="Food title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
              </div>
              <div className="formInput">
                <label>Desc</label>
                <input type="text" name="desc" placeholder="Food Description" value={formData.desc} onChange={(e) => setFormData({ ...formData, desc: e.target.value })} />
              </div>
              <div className="formInput">
                <label>Category</label>
                <select name="category" value={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
                  <option value="" disabled>Select a category</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                {foodDetails && foodDetails.requests.map((request, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      value={request.name}
                      onChange={(e) => handleInputChange(request.id, 'name', e.target.value)}
                      placeholder={`Request item ${index + 1}`}
                    />
                    <input
                      type="text"
                      value={request.addOnPrice}
                      onChange={(e) => handleInputChange(request.id, 'addOnPrice', e.target.value)}
                      placeholder={`RM ${index + 1}`}
                    />

                    <button onClick={() => deleteRequest(request.id)}>Delete</button>
                  </div>
                ))}
              </div>
              <button type="button" onClick={addRequest}>Add Request</button>

              <div className="formInput">
                <label>Price</label>
                <input type="text" name="price" placeholder="Price" value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
              </div>
              <div className="formInput">
                <label htmlFor="file">
                  Food Image <DriveFolderUploadIcon className="icon" />
                </label>
                <input type="file" id="file" style={{ display: 'none' }} onChange={handleFileChange} />
                {file && <img src={previewUrl} alt="Preview" style={{ maxWidth: '15%', marginTop: '1px' }} />}
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Edit;